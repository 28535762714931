<template>
  <router-view />
</template>
<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
#app {
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-overflow-scrolling: touch;
}
</style>
