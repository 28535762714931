import http from '@/utils/http.js'

let API = ''
if (process.env.NODE_ENV === 'production') {
  API = 'http://47.94.162.125:8859/'
} else {
  API = '/apii'
}

//pvuv检测接口
export function setPvUv (params) {
  params.links = window.location.href
  params.session = window.sessionStorage.getItem('sessionToken')
  return http.post(API + '/xgyqy/lt/addPoint', params)
}


