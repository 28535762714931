<template>
  <div class="home">
    <!-- 自动消失弹窗 -->
    <div class="isShow" v-show="isShow">
      <div>{{ message }}</div>
    </div>

    <!--  确认取消弹窗-->
    <div class="pop-box" v-if="isShow1">
      <div class="pop-content">
        <div v-html="content"></div>
        <div class="button">
          <div class="button1" @click="isShow1 = false">{{ btnFalse }}</div>
          <div class="button2" @click="btnClick">{{ btnText }}</div>
        </div>
      </div>
    </div>

    <!--    纯文本弹窗-->
    <div class="plaintext-box" v-if="isPlainTextShow" @click="isPlainTextShow=false">
      <div class="plaintext-content" @click.stop="isPlainTextShow=true">
        <div class="plaintext-content-data" v-html="content" style="color: #8f8f8f">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  data() {
    return {
      message: '',
      content: '',
      click: '',
      btnFalse: '取消',
      btnText: '确认',
      isShow: false,
      isShow1: false,
      isPlainTextShow: false,
    }
  },
  methods: {
    // 自动消失弹窗
    show(message = '默认文本', duration = 1000) {
      this.message = message
      this.isShow = true
      // 指定时间后弹框消失
      setTimeout(() => {
        this.isShow = false
      }, duration)
    },

    //确认取消弹窗
    confirm(item) {
      this.isShow1 = true
      this.btnFalse = item.btnFalse
      this.btnText = item.btnText
      this.content = item.content
      this.click = item.click
    },
    btnClick() {
      this.click()
      this.isShow1 = false
    },

    //纯文本弹窗
    plaintext(item) {
      this.isPlainTextShow = true
      this.content = item
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  .isShow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 4vw 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    font-size: 4vw;
    background-color: black;
    opacity: .7;
    border-radius: 2vw;
    text-align: center;
    color: white;
    z-index: 100;
    animation: mov .1s linear;
    word-break: break-all;
  }
  .pop-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
    .pop-content {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      background: #fff;
      border-radius: 2vw;
      padding: 6vw 4vw;
      .button {
        display: flex;
        justify-content: space-around;
        .button1, .button2 {
          width: 30vw;
          height: 8vw;
          line-height: 8vw;
          color: #9f9f9f;
          background: #dddddd;
          border-radius: 10vw;
          font-size: 4vw;
          padding: 1vw;
          text-align: center;
        }
        .button2 {
          color: #ffffff;
          background: #ff524c;
        }
      }
    }
  }
  .plaintext-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    .plaintext-content {
      width: 80%;
      height: 120vw;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      background: #fff;
      border-radius: 2vw;
      padding: 6vw 4vw;
      overflow-y: auto;
      /*.plaintext-content-data {
        width: 100%;
        height: 100%;
        margin: 1vw auto;
        overflow-y: auto;
      }*/
      div {
        line-height: 4.6667vw;
      }
    }
  }
}
@keyframes mov {
  from {
    top: 80%;
  }
  to {
    top: 50%;
  }
}
</style>
