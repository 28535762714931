import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { getLocalStorageAndTime } from '@/utils/token'

const routes = [
  {
    path: '/',
    redirect: '/404',
  },

  /**
   *  小红书零元购常规版本
   */
  {
    path: '/general/home',
    name: 'GHome',
    component: () => import('@/views/general/Home'),
    meta: {
      title: '首页',
    }
  }, {
    path: '/general/login',
    name: 'GLogin',
    component: () => import('@/views/general/Login'),
    meta: {
      title: '登录'
    }
  }, {
    path: '/general/prize',
    name: 'GPrize',
    component: () => import('@/views/general/Prize'),
    meta: {
      title: '我的奖品',
      requireAuth: true,
      version: 'general'
    }
  }, {
    path: '/general/prizeform',
    name: 'GPrizeForm',
    component: () => import('@/views/general/PrizeForm'),
    meta: {
      title: '获奖信息',
      requireAuth: true,
      version: 'general'
    }
  }, {
    path: '/general/prizedetail',
    name: 'GPrizeDetail',
    component: () => import('@/views/general/PrizeDetail'),
    meta: {
      title: '砍价详情',
      requireAuth: true,
      version: 'general'
    }
  }, {
    path: '/general/rule',
    name: 'GRule',
    component: () => import('@/views/general/Rule'),
    meta: {
      title: '活动规则'
    }
  },
  {
    path: '/general/share',
    name: 'GShare',
    component: () => import('@/views/general/Share'),
    meta: {
      title: '分享砍价成功'
    }
  }, {
    path: '/general/prizefree',
    name: 'GPrizeFree',
    component: () => import('@/views/general/PrizeFree'),
    meta: {
      title: '免费领取奖品',
    }
  }, {
    path: '/yzfqueencard/QueenCard',
    name: 'GQueenCard',
    component: () => import('@/components/yzfqueencard/QueenCard'),
    meta: {
      title: '女王卡办理页面',
    }
  },

  /**
   * 小红书零元购活动校园版本
   */
  {
    path: '/school/home',
    name: 'SchoolHome',
    component: () => import('@/views/school/Home'),
    meta: {
      title: '首页',
    }
  }, {
    path: '/school/login',
    name: 'SchoolLogin',
    component: () => import('@/views/school/Login'),
    meta: {
      title: '登录'
    }
  }, {
    path: '/school/prize',
    name: 'SchoolPrize',
    component: () => import('@/views/school/Prize'),
    meta: {
      title: '我的奖品',
      requireAuth: true,
      version: 'school'
    }
  }, {
    path: '/school/prizeform',
    name: 'SchoolPrizeForm',
    component: () => import('@/views/school/PrizeForm'),
    meta: {
      title: '获奖信息',
      requireAuth: true,
      version: 'school'
    }
  }, {
    path: '/school/prizedetail',
    name: 'SchoolPrizeDetail',
    component: () => import('@/views/school/PrizeDetail'),
    meta: {
      title: '砍价详情',
      requireAuth: true,
      version: 'school'
    }
  }, {
    path: '/school/rule',
    name: 'SchoolRule',
    component: () => import('@/views/school/Rule'),
    meta: {
      title: '活动规则'
    }
  },
  {
    path: '/school/share',
    name: 'SchoolShare',
    component: () => import('@/views/school/Share'),
    meta: {
      title: '分享砍价成功'
    }
  }, {
    path: '/school/selfshare',
    name: 'SchoolSelfShare',
    component: () => import('@/views/school/SelfShare'),
    meta: {
      title: '自砍成功'
    }
  }, {
    path: '/school/helpshare',
    name: 'SchoolHelpShare',
    component: () => import('@/views/school/HelpShare'),
    meta: {
      title: '帮砍成功'
    }
  }, {
    path: '/school/prizefree',
    name: 'SchoolPrizeFree',
    component: () => import('@/views/school/PrizeFree'),
    meta: {
      title: '免费领取奖品',
    }
  },

  /**
   * 线上拉新地址
   */
  {
    path: '/online/home',
    name: 'OnlineHome',
    component: () => import('@/views/online/Home'),
    meta: {
      title: '',
    }
  },
  {
    path: '/online/share',
    name: 'OnlineShare',
    component: () => import('@/views/online/Share'),
    meta: {
      title: '',
    }
  },

  /**
   * 翼支付测试地址入口
   */
  {
    path: '/xgy/home',
    name: 'XGYHome',
    component: () => import('@/views/xgyentry/Home'),
    meta: {
      title: '讯冠易测试入口',
    }
  },
  /**
   * 0元购拉新用户查询
   */
  {
    path: '/newquery/home',
    name: 'NewQuery',
    component: () => import('@/views/newquery/Home'),
    meta: {
      title: '0元购拉新新用户查询',
    }
  },

  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  history: createWebHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes,
})
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (getLocalStorageAndTime()) {
      next()
    } else {
      if (to.meta.version == 'school') {
        next('/school/login')
      } else if (to.meta.version == 'general') {
        next('/general/login')
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
