const general = {
  namespaced: true,
  state: {
    pubAccount: '',
    prvAccount: '',
    actCode: '',
    prvLinke: '',
    cps1: ''
  },
  getters: {},
  mutations: {
    //存储token方法
    //设置token等于外部传递进来的值
    setToken(state, token) {
      localStorage.setItem(token.token + 'token', JSON.stringify({
        phone: token.userinfo,
        time: token.time,
        token: state.token
      }))
      state.pubAccount = token.pubAccount
      state.prvAccount = token.prvAccount
      state.actCode = token.actCode
      state.prvLinke = token.prvLinke
    },
    setCps1(state, cps1) {
      state.cps1 = cps1
    }
  },
  actions: {}
}
export default general
