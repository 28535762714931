import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import school from './module/school'
import general from './module/general'

export default createStore({
  state: {
    token: ''
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    school,
    general
  },
  plugins: [createPersistedState({
    storage: window.localStorage
  })]
})
