/*
/!* rem 尺寸换算 1rem = 100px *!/
(function(doc, win) {
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function() {
            var clientWidth = docEl.clientWidth
            if (!clientWidth) return
            if (clientWidth >= 640) {
                docEl.style.fontSize = '100px'
            } else {
                docEl.style.fontSize = 100 * (clientWidth / 750) + 'px'
            }
        }
    if (!doc.addEventListener) return
    win.addEventListener(resizeEvt, recalc, false)
    doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
*/

(function(win, lib) {
    var doc = win.document
    var docEl = doc.documentElement
    var metaEl = doc.querySelector('meta[name="viewport"]')
    var flexibleEl = doc.querySelector('meta[name="flexible"]')
    var dpr = 0
    var scale = 0
    var tid
    var flexible = lib.flexible || (lib.flexible = {})
    if (metaEl) {
        console.warn('将根据已有的meta标签来设置缩放比例')
        var match = metaEl.getAttribute('content').match(/initial\-scale=([\d\.]+)/)
        if (match) {
            scale = parseFloat(match[1])
            dpr = parseInt(1 / scale)
        }
    } else {
        if (flexibleEl) {
            var content = flexibleEl.getAttribute('content')
            if (content) {
                var initialDpr = content.match(/initial\-dpr=([\d\.]+)/)
                var maximumDpr = content.match(/maximum\-dpr=([\d\.]+)/)
                if (initialDpr) {
                    dpr = parseFloat(initialDpr[1])
                    scale = parseFloat((1 / dpr).toFixed(2))
                }
                if (maximumDpr) {
                    dpr = parseFloat(maximumDpr[1])
                    scale = parseFloat((1 / dpr).toFixed(2))
                }
            }
        }
    }
    if (!dpr && !scale) {
        var isAndroid = win.navigator.appVersion.match(/android/gi)
        var isIPhone = win.navigator.appVersion.match(/iphone/gi)
        var devicePixelRatio = win.devicePixelRatio
        if (isIPhone) {
            if (devicePixelRatio >= 3 && (!dpr || dpr >= 3)) {
                dpr = 3
            } else {
                if (devicePixelRatio >= 2 && (!dpr || dpr >= 2)) {
                    dpr = 2
                } else {
                    dpr = 1
                }
            }
        } else {
            dpr = 1
        }
        scale = 1 / dpr
    }
    docEl.setAttribute('data-dpr', dpr)
    if (!metaEl) {
        metaEl = doc.createElement('meta')
        metaEl.setAttribute('name', 'viewport')
        metaEl.setAttribute('content', 'initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no')
        if (docEl.firstElementChild) {
            docEl.firstElementChild.appendChild(metaEl)
        } else {
            var wrap = doc.createElement('div')
            wrap.appendChild(metaEl)
            doc.write(wrap.innerHTML)
        }
    }

    function refreshRem() {
        var width = docEl.getBoundingClientRect().width
        if (width / dpr > 750) {
            width = 750 * dpr
        }
        var rem = width / 10
        docEl.style.fontSize = rem + 'px'
        flexible.rem = win.rem = rem
    }

    win.addEventListener('resize',
        function() {
            clearTimeout(tid)
            tid = setTimeout(refreshRem, 300)
        },
        false)
    win.addEventListener('pageshow',
        function(e) {
            if (e.persisted) {
                clearTimeout(tid)
                tid = setTimeout(refreshRem, 300)
            }
        },
        false)
    if (doc.readyState === 'complete') {
        doc.body.style.fontSize = 12 * dpr + 'px'
    } else {
        doc.addEventListener('DOMContentLoaded',
            function(e) {
                doc.body.style.fontSize = 12 * dpr + 'px'
            },
            false)
    }
    refreshRem()
    flexible.dpr = win.dpr = dpr
    flexible.refreshRem = refreshRem
    flexible.rem2px = function(d) {
        var val = parseFloat(d) * this.rem
        if (typeof d === 'string' && d.match(/rem$/)) {
            val += 'px'
        }
        return val
    }
    flexible.px2rem = function(d) {
        var val = parseFloat(d) / this.rem
        if (typeof d === 'string' && d.match(/px$/)) {
            val += 'rem'
        }
        return val
    }
})(window, window.lib || (window.lib = {}));

(function() {
    function resetFontSize() {
        setTimeout(function() {
            // 设置网页字体为默认大小
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 })
        }, 0)
        // 重写设置网页字体大小的事件
        WeixinJSBridge.on('menu:setfont', function() {
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 })
        })
    }

    if (typeof WeixinJSBridge === 'undefined') {
        document.addEventListener('WeixinJSBridgeReady', function(e) {
            resetFontSize()
        })
    } else {
        resetFontSize()
    }
})()

// 将px自动转换成rem,根据屏幕大小计算合理的html元素的font-size值
// (function () {
//     var initFontSize = 16
//     var iPhone6Width = 375
//     var clientWidth = window.document.documentElement.clientWidth || iPhone6Width
//     var newFontSize = initFontSize * (clientWidth / iPhone6Width)
//     document.documentElement.style.fontSize = newFontSize + 'px'
//   })();


